
    import {computed, defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
    import ApiUser from "@/request/apis/api_user";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    interface IState {
        searches: any,
        total: any,
        list: any,
        treeData: any,
        platform_cate: any,
        title: any,
        visible: any,
        orderData: any,
        loading:any,
        detailData:any,
        examData:any,
        visible2:any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize
                },
                total: 0,
                platform_cate: [],
                list: [],
                treeData: [],
                title: "",
                visible: false,
                orderData: '',
                loading:false,
                detailData:'',
                examData:'',
                visible2:false
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '店铺名称',
                    dataIndex: 'shop',
                    key: 'shop',
                },
                {
                    title: '原因',
                    dataIndex: 'application',
                    key: 'application',
                },
                {
                    title: '审核备注',
                    dataIndex: 'reason',
                    key: 'reason',
                },
                {
                    title: '审核时间',
                    dataIndex: 'check_time',
                    key: 'check_time',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150
                },
            ];

            const getList = async () => {
                state.loading = true
                const params = {...state.searches}
                const res = await ApiUser.destroyIndex(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.total = data.count
                    state.list = data.list
                    state.loading = false
                }
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };


            const OnSearch = () => {
                state.searches.page_size = PageUtil.pageSize
                state.searches.page_no = PageUtil.pageNo
                getList()
            }

            const OnReset = () => {
                state.searches.shop_name = ''
                state.searches.goods_name = ''
                state.searches.platform_cate_id = ''
                state.platform_cate = []
                state.searches.page_no = PageUtil.pageNo
                state.searches.page_size = PageUtil.pageSize
                getList()
            }




            const getDetail = (val) => {
                state.detailData = val
                state.visible = true
            }

            const examine = (val) =>{
                state.examData = {
                    id:val.id,
                    reason:'',
                    is_pass:''
                }
                state.visible2 = true
            }

            const examSubmit = async () => {
                const data = {...state.examData}
                const res = await ApiUser.destroyAudit(data)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    getList()
                    state.visible2 = false
                }else{
                    message.error(res.msg)
                }
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                examSubmit,
                examine,
                getDetail,
                OnReset,
                columns,
                OnSearch,
                pagination,
                handleTableChange
            }
        }
    });
